import React from 'react';
import { Typography } from '@mui/material';

const OrderEmptyPage = ({ onSubmit }) => {

    // Intentionally throw an error for testing
    //throw new Error("Test emergency error!");
    //------------------------------------------

    return (<Typography></Typography>)
};
export default OrderEmptyPage;
