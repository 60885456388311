export const images = [
    'https://images.prom.ua/4642219667_4642219667.jpg',
    'https://images.prom.ua/4672544311_4672544311.jpg',
    'https://images.prom.ua/4672581210_4672581210.jpg',
    'https://images.prom.ua/4672603411_4672603411.jpg',
    'https://images.prom.ua/4672623763_4672623763.jpg',
    'https://images.prom.ua/4672666102_4672666102.jpg'
];

// export const config = {
//     'storeUtility': 'LocalStorage',
//     //'storeUtility': 'ReduxStore',
// }