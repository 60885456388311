import CryptoJS from 'crypto-js';
import {generateKey} from "../data";

/**
 * Utility function to get the token from localStorage.
 *
 * @returns {string|null} The token if it exists, otherwise null.
 */
// export const getTokenFromLocalStorage = () => {
//     //console.log('token', localStorage.getItem('token'));
//     return localStorage.getItem('token');
// };

//const secretKey = `your-${generateKey()}-key`
const secretKey = `your-secret-key`;

export const getTokenFromLocalStorage = () => {
    const encryptedToken = localStorage.getItem('token');
    //console.log('token', encryptedToken);
    if (encryptedToken) {
        const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
        return bytes.toString(CryptoJS.enc.Utf8);
    }
    return null;
};