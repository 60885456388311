import CryptoJS from 'crypto-js';
import {generateKey} from "../data";

/**
 * Utility function to set the token to localStorage.
 */

// export const setTokenToLocalStorage = (token) => {
//     return localStorage.setItem('token',token);
// };

//const secretKey = `your-${generateKey()}-key`;
const secretKey = `your-secret-key`;

export const setTokenToLocalStorage = (token) => {
    const encryptedToken = CryptoJS.AES.encrypt(token, secretKey).toString();
    localStorage.setItem('token', encryptedToken);
};